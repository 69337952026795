const uz = {
  Продолжить: "Davom etish",
  Подтвердить: "Tasdiqlash",
  "Введите код из СМС": "SMS-dan kelgan kodni kiriting",
  "Каталог ЖК": "Yangi binolar",
  "Карта новостроек": "Yangi binolar xaritasi",
  Квартиры: "Kvartiralar",
  Застройщик: "Quruvchi",
  Застройщики: "Quruvchilar",
  Журнал: "Jurnal",
  Изменить: "O'zgartirish",
  Меню: "Menyu",
  "Войти или зарегистрироваться": "Kirish yoki ro'yxatdan o'tish",
  Выйти: "Chiqish",
  Избранное: "Sevimli",
  "Ваш город": "Sizning shahringiz",
  "Войти или создать личный кабинет": "Kirish yoki shaxsiy kabinet yaratish",
  "Номер телефона": "Telefon raqami",
  "Загрузите сейчас! Не пропустите акции и скидки":
    "Hoziroq yuklab oling! Chegirmalarni o'tkazib yubormang",
  "Наведите камеру на QR-код, чтобы скачать":
    "Yuklab olish uchun kamerani QR kodiga yo'naltiring",
  "Для застройщиков": "Quruvchilar uchun",
  "Колл-центр": "Qo'ng'iroq markazi",
  "Другие ЖК от застройщика": "Quruvchining boshqa TJMlari",
  Tugash_muddati: "Topshirish muddati",
  Ipoteka: "Bo'lib to'lash",
  Uy_sinfi: "Uy-joy sinfi",
  Xonadonlar: "Xonadonlar",
  Infrastruktura: "Infrastruktura",
  Genplan: "Genplan",
  Haqida: "TJM haqida",
  Ulashish: "Ulashish",
  Saqlanganlar: "Sevimlilarga qo'shish",
  ЖК: "TJM",
  Ipoteka_text: "ajratilmaydi",
  Quruvchi: "Quruvchi",
  "Узнать цену": "Narxni bilish",
  "Nusxa olish": "Nusxa olish",
  "Nusxa olindi": "Nusxa olindi",
  title: "Yangi binolar Toshkentda | Kvartiralar Toshkendta",
  Договорная: "Kelishuvli",
  "Показать телефон": "Telefonni ko'rsatish",
  "Перезвоните мне": "Menga qo'ng'iroq qiling",
  "Ushbu sahifa topilmadi!": "Ushbu sahifa topilmadi!",
  "Sahifa mavjud emas":
    "Kechirasiz, siz qidirayotgan sahifa mavjud emas yoki ko‘chirilgan.",
  "Bosh sahifaga qaytish": "Bosh sahifaga qaytish",
  "Yuklab olish": "Yuklab olish",
  Saqlash: "Saqlash",
  Til: "Til",
  Valyuta: "Valyuta",
  expo_title: "TABRIKLAYMIZ! SIZ UYSOT EXPO ISHTIROKCHISIGA AYLANDINGIZ!",
  expo_ticket:
    "Biletni yuklab oling va 2-3 mart kunlari bo’lib o’tadigan “UYSOT EXPO”da ishtirok eting.",
  expo_body: "SIZDA USHBU SOVG’ALARNI YUTISH IMKONI BOR",
  Dubayga_chipta: "Dubayga chipta",
  "Umra ziyoratiga yo’llanma": "Umra ziyoratiga yo’llanma",
  "50 dan ortiq boshqa sovg’alar": "50 dan ortiq boshqa sovg’alar",
  expo_footer:
    "Qoidalar: Ushbu yutuqli o’yinda ishtirok etish uchun 2-3 mart kunlari UYSOT EXPOga tashrif buyurishingiz lozim",
  dynamic_empty_title: "Aktual ma’lumotlar mavjud emas!",
  dynamic_empty_descr:
    "Kechirasiz, siz qidirayotgan TJM bo’yicha aktual ma’lumotlar mavjud emas. Tez orada shakllanadi.",
  Tez_tez_beriladigan_savollar: "Tez-tez beriladigan savollar",
  Eng_kop_beriladigan_savollar: "Eng ko’p beriladigan savollar",
  TOP: "TOP",
  Toshkentdagi_yangi_uylar: "Toshkent shahridan yangi binolar",
  Toshkentdagi_yangi_kvartiralar: "Toshkent shahridan kvartiralar",
  Butun_Ozbekiston_boylab_yangi_uylar:
    "Butun O‘zbekiston bo‘ylab yangi binolar",
  Butun_Ozbekiston_boylab_kvartiralar: "Butun O'zbekiston bo'ylab kvartiralar",
  takliflar: "takliflar",
  Batafsil: "Batafsil",
  Hammasi: "Hammasini ko’rish",
  Qurilish_jarayoni: "Qurilish jarayoni",
  notif_title: "Cheklangan takliflarga a'zo bo'ling",
  notif_text:
    "Faqat a'zolar uchun mo'ljallangan eksklyuziv chegirmalar va aksiyalardan birinchi bo'lib foydalangan holda xarid qiling.",
  notif_button: "Obuna bo’lish",
  ishonchli: "Ishonchli",
  tasdiqlangan: "Tasdiqlangan",
  seo_text: `Bizning veb-saytimizda O'zbekistondagi har qanday ko'chmas mulklar turlarini topasiz. Kvartiralar va boshqa binolarning keng tanlovi mavjud. O'zbekistondagi yangi binolar haqida dolzarb ma'lumotlarni saytimizda chop etib boramiz. Katalogda quruvchilarning joriy narxlari ko'rsatilgan. Barcha obyektlar bo'yicha ma'lumotlarning mavjudligi, shu jumladan texnik xususiyatlar, kvartiralarning planirovkalari O'zbekistondan ko'chmas mulk sotib olishga yordam beradi. Bizning saytda kvartiralarning narxlari aktual holatda bo’ladi. <br /> <br />  <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> saytidan O'zbekistondagi eng arzon, ishonchli va sifatli yangi uylarni toping. Ijaraga turishdan charchadingizmi? Boshlang'ich to'lovsiz, bo’lib to’lashga yoki ipotekaga olinadigan yangi kvartiralar va uylar sizni kutmoqda. Bizning platformamizda karobka holatidagi uylardan tortib, to'liq remontli uylar, zamonaviy kvartiralar va tayyor holatdagi xonadonlargacha bo'lgan barcha turdagi takliflarni kashf eting. Yangi qurilgan uylar haqida eng so'nggi takliflarni o'rganib, orzuingizdagi uyni eng yaxshi va eng arzon narxlarda toping. <br /> <br /> <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> ijaradan voz kechish va o'zingizning uyingizga ega bo'lish yo'lingizdagi ishonchli sherik. Eng arzon narxlarda yangi uylarni kreditga yoki ipotekaga sotib olish imkoniyatlari bizning saytimizda.`,
  seo_title: "O’zbekiston bo’ylab yangi uylar",
}
const ru = {
  Продолжить: "Продолжить",
  Подтвердить: "Подтвердить",
  "Введите код из СМС": "Введите код из СМС",
  "Каталог ЖК": "Новостройки",
  "Карта новостроек": "Карта новостроек",
  Квартиры: "Квартиры",
  Застройщик: "Застройщик",
  Застройщики: "Застройщики",
  Журнал: "Журнал",
  Изменить: "Изменить",
  Меню: "Меню",
  "Войти или зарегистрироваться": "Войти или зарегистрироваться",
  Выйти: "Выйти",
  Избранное: "Избранное",
  "Ваш город": "Ваш город",
  "Войти или создать личный кабинет": "Войти или создать личный кабинет",
  "Номер телефона": "Номер телефона",
  "Загрузите сейчас! Не пропустите акции и скидки":
    "Скачать сейчас! Не пропустите скидки",
  "Наведите камеру на QR-код, чтобы скачать":
    "Наведите камеру на QR-код, чтобы скачать",
  "Для застройщиков": "Для застройщиков",
  "Колл-центр": "Колл-центр",
  "Другие ЖК от застройщика": "Другие ЖК от застройщика",
  Tugash_muddati: "Срок сдачи",
  Ipoteka: "Рассрочка",
  Uy_sinfi: "Класс жилья",
  Xonadonlar: "Квартиры",
  Infrastruktura: "Инфраструктура",
  Genplan: "Генплан",
  Haqida: "Информация о ЖК",
  Ulashish: "Поделиться",
  Saqlanganlar: "В избранное",
  ЖК: "ЖК",
  Ipoteka_text: "не предостовлено",
  Quruvchi: "Строитель",
  "Узнать цену": "Узнать цену",
  "Nusxa olish": "Копировать",
  "Nusxa olindi": "Скопировано",
  title: "Новостройки в Ташкенте | Квартиры в Ташкенте",
  Договорная: "Договорная",
  "Показать телефон": "Показать телефон",
  "Перезвоните мне": "Перезвоните мне",
  "Ushbu sahifa topilmadi!": "Эта страница не найдена!",
  "Sahifa mavjud emas":
    "Извините, страница, которую вы ищете, не существует или была перемещена.",
  "Bosh sahifaga qaytish": "Вернуться на главную",
  "Yuklab olish": "Скачать",
  Saqlash: "Сохранить",
  Til: "Язык",
  Valyuta: "Валюта",
  expo_title: "ПОЗДРАВЛЯЕМ! ВЫ СТАЛИ УЧАСТНИКОМ UYSOT EXPO!",
  expo_ticket:
    "Скачивайте билет и участвуйте в выставке «UYSOT EXPO», которая пройдет 2-3 марта.",
  expo_body: "У ВАС ЕСТЬ ШАНС ВЫИГРАТЬ ЭТИ ПРИЗЫ",
  Dubayga_chipta: "Билет в Дубай",
  "Umra ziyoratiga yo’llanma": "Билет на паломничество Умра",
  "50 dan ortiq boshqa sovg’alar": "Более 50 других подарков",
  expo_footer:
    "Правила: Для участия в этой выигрышной игре необходимо посетить UYSOT EXPO 2-3 марта.",
  dynamic_empty_title: "Актуальной информации нет!",
  dynamic_empty_descr:
    "К сожалению, актуальной информации о ЖК, который вы ищете, нет. Скоро формируемся.",
  Tez_tez_beriladigan_savollar: "Часто задаваемые вопросы",
  Eng_kop_beriladigan_savollar: "Наиболее часто задаваемые вопросы",
  TOP: "ТОП",
  Toshkentdagi_yangi_uylar: "Новостройки города Ташкента",
  Toshkentdagi_yangi_kvartiralar: "Квартиры города Ташкента",
  Butun_Ozbekiston_boylab_yangi_uylar: "Новостройки по всему Узбекистану",
  Butun_Ozbekiston_boylab_kvartiralar: "Квартиры по всему Узбекистану",
  takliflar: "предложения",
  Batafsil: "Подробнее",
  Hammasi: "Показать все",
  Qurilish_jarayoni: "Процесс строительства",
  notif_title: "Подпишитесь на ограниченные предложения",
  notif_text:
    "Будьте первым, кто совершает покупки, используя эксклюзивные скидки и акции только для участников.",
  notif_button: "Подпишитесь",
  ishonchli: "Надежный",
  tasdiqlangan: "Подтвержденный",
  seo_text: `Устали от аренды? Настало время воплотить мечту о собственном жилье с нашей платформой! На <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> вы найдете самые доступные, качественные и надежные новостройки по всему Узбекистану. В нашем каталоге представлены современные квартиры и дома на любой вкус — от квартир безремонта до полностью отремонтированных жилых помещений. <br /> <br /> Мы предлагаем самые актуальные предложения с возможностью беспроцентной рассрочки, кредита и ипотеки, чтобы покупка жилья стала реальностью для каждого. Благодаря техническим характеристикам и планировкам вы сможете легко выбрать дом своей мечты. Наши данные регулярно обновляются, поэтому вы всегда будете в курсе последних цен и предложений. <br /> <br /> <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> — ваш надежный партнер на пути к приобретению собственного жилья. Забудьте об аренде, выберите новый дом по доступной цене уже сегодня!`,
  seo_title: "Новостройки в Узбекистане",
}
export const layoutLang: {
  [key: string]: typeof uz | any
} = {
  uz,
  ru,
}
